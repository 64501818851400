<template>
  <div class="pb-8">
    <portal-section-header title="履歴" icon="mdi-clock" :show-unavailable-message="disablePersonalFeature" />
    <p v-if="disablePersonalFeature" class="noEntry">
      履歴がありません。検索をしたり文献を閲覧すると、こちらに履歴が表示されるようになります。
    </p>
    <template v-else>
      <div v-if="isHistoryLoading" class="text-center">
        <v-progress-circular indeterminate />
      </div>
      <p v-else-if="history.length === 0" class="noEntry">
        履歴がありません。検索をしたり文献を閲覧すると、こちらに履歴が表示されるようになります。
      </p>
      <div v-else>
        <v-fade-transition group>
          <template v-for="(entry, j) in currentHistoryData">
            <v-row :key="'history-' + (assertHasDocument(entry) ? entry.document.id : entry.timeStamp) + j">
              <document-list-item
                v-if="assertHasDocument(entry)"
                :document="entry.document"
                :disabled="true"
                variant="small"
                style="margin-left: 60px !important"
                :viewer-click-callback="historyDocumentTitleClickHandler"
              >
                <v-row class="grey--text ml-2 mb-2">
                  {{ formatYmdHms(entry.timeStamp) }}
                </v-row>
                <v-row v-for="range in entry.ranges" :key="range.fromSeq + '-' + range.toSeq" class="ml-4">
                  <template v-if="range.fromKey !== void 0 && range.toKey !== void 0">
                    <template v-if="entry.document.toc">
                      {{ entry.document.toc.byKey[range.fromKey].label }}
                      <template v-if="range.fromKey !== range.toKey">
                        ～
                        {{ entry.document.toc.byKey[range.toKey].label }}
                      </template>
                    </template>
                    <span class="grey--text mr-2 ml-2">付近</span>
                  </template>
                  <nuxt-link
                    :to="`/document/${entry.document.id}#page=${range.fromSeq + 1}`"
                    class="mx-1"
                    @click.native="historyDocumentPageNoClickHandler"
                  >
                    <template v-if="isAccessible(entry.document) && entry.document.folioPerSeq">
                      {{
                        entry.document.folioPerSeq[range.fromSeq].trim() === ''
                          ? 'ページ番号なし'
                          : `${entry.document.folioPerSeq[range.fromSeq]}ページ`
                      }}
                    </template>
                    <template v-else>
                      {{ `${range.fromSeq + 1}ページ` }}
                    </template>
                  </nuxt-link>
                  <template v-if="range.fromSeq !== range.toSeq">
                    ～
                    <nuxt-link
                      :to="`/document/${entry.document.id}#page=${range.toSeq + 1}`"
                      class="mx-1"
                      @click.native="historyDocumentPageNoClickHandler"
                    >
                      <template v-if="isAccessible(entry.document) && entry.document.folioPerSeq">
                        {{
                          entry.document.folioPerSeq[range.toSeq].trim() === ''
                            ? 'ページ番号なし'
                            : `${entry.document.folioPerSeq[range.toSeq]}ページ`
                        }}
                      </template>
                      <template v-else>
                        {{ `${range.toSeq + 1}ページ` }}
                      </template>
                    </nuxt-link>
                  </template>
                </v-row>
              </document-list-item>
              <div v-else-if="!isTagOnlyQuery(entry.query)" class="grey--text ml-6 mt-6">
                {{ formatYmdHms(entry.timeStamp) }}
                <v-icon>mdi-magnify</v-icon>
                <nuxt-link :to="generateHistoryRoute(entry.query)" @click.native="historySearchClickHandler">
                  {{ formatQuery(entry.query) }}
                </nuxt-link>
              </div>
            </v-row>
          </template>
        </v-fade-transition>
        <v-row v-if="historyTotal > currentHistoryData.length" class="ma-8">
          <v-btn large block :loading="isHistoryLoading" @click="historyReadmoreClickHandler">
            <v-icon>mdi-arrow-down-bold</v-icon>
            もっと読み込む...
          </v-btn>
        </v-row>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import PortalSectionHeader from '@/components/portalSection/portal-section-header.vue';
import * as Constants from '@/constants';
import { generateSearchRoute, SearchLocation } from '@/utils/routeUtils';
import { PartialSearchQuery, SearchQuery } from '@/types/SearchQuery';
import { formatQuery, formatYmdHms } from '@/utility';
import { DocBrowseEventExcerptWithRange, ParsedSearchEvent } from 'wklr-backend-sdk/repos/histories';
import DocumentListItem from '@/components/document-list-item.vue';
import { isAccessible } from '@/utils/documentUtils';
import { RecursivePartial } from '@/types/RecursivePartial';

type History = DocBrowseEventExcerptWithRange | ParsedSearchEvent;

@Component({
  components: {
    PortalSectionHeader,
    DocumentListItem,
  },
})
export default class HistorySection extends Vue {
  formatQuery = formatQuery;
  formatYmdHms = formatYmdHms;
  isAccessible = isAccessible;

  @Prop({ required: false, default: false }) disablePersonalFeature!: boolean;

  /** 履歴データ */
  history: History[] = [];

  /** 履歴データを次はfromいくつから取得すればよいか */
  historyOffset = 0;

  /** 履歴の表示中の位置 */
  historyCurrent = 1;

  /** 履歴データの総合計 */
  get historyTotal(): number {
    return this.historyDocumentTotal + this.historySearchTotal - this.historySearchInvalidCount;
  }

  /** 文献閲覧履歴のトータル数 */
  historyDocumentTotal = 0;

  /** 検索履歴のトータル数 */
  historySearchTotal = 0;

  /** 検索履歴のうち、不正な履歴のカウント */
  // FIXME: history api が修正されたらフロントで保持する必要がないので消したい
  historySearchInvalidCount = 0;

  /** 履歴データの読み込み状態 */
  isHistoryLoading = false;

  /** 表示用の履歴データ */
  get currentHistoryData(): History[] {
    return this.history.slice(0, this.historyCurrent * Constants.Portal.PerPage);
  }

  generateHistoryRoute(query: PartialSearchQuery): SearchLocation {
    return generateSearchRoute(query, Constants.Search.option);
  }

  isTagOnlyQuery(query: RecursivePartial<SearchQuery>): boolean {
    return Object.keys(query).length === 1 && query.tagId !== undefined;
  }

  mounted(): void {
    this.loadPage();
  }

  @Watch('historyCurrent')
  async loadPage(): Promise<void> {
    // FIXME: 履歴のタブだけは二つのAPIに分離してしまっている
    //        これらを時系列順に正しくpaginationするのは結構面倒くさいので
    //        嘘実装として多めにとって気づきにくいようにする方針で当座間に合わせる
    //        2種類の履歴がある時刻前後で著しく片方ばかりに集中してしまっている（e.g. 一度も閲覧せずに検索APIを100回呼ぶ）と
    //        「もっと読み込む」を押したときに一番下以外に要素が増える可能性が高い

    const from = (this.historyCurrent - 1) * Constants.Portal.PerPage;
    const to = from + Constants.Portal.PerPage - 1;

    if (this.history[from] && (this.history.length < to || this.history[to])) {
      // すでにAPIコール済み
      return;
    }

    try {
      this.isHistoryLoading = true;

      const [docs, searches] = await Promise.all([
        this.$repositories.histories.getDocHistories(this.historyOffset, Constants.Portal.PerPage * 10),
        this.$repositories.histories.getSearchHistories(this.historyOffset, Constants.Portal.PerPage * 10),
      ]);

      // documentが無いものは除外する

      // docs.total, searches.total は API から返される総量
      this.historyDocumentTotal = docs.total;
      this.historySearchTotal = searches.total;
      // searches.invalidCount は処理した範囲内の不正な値のカウント（ docs に inValid は存在しない
      this.historySearchInvalidCount = this.historySearchInvalidCount + searches.invalidCount;

      // なお、 concat したものを時系列順に並べるのは本来は間違っている。
      // (そもそもユーザーの "一連の" 行動を記録するということを目的としたデータの採取を考える必要がある。)
      this.history = this.history
        .concat(docs.excerpt, searches.excerpt)
        .sort((a, b) => b.timeStamp.localeCompare(a.timeStamp));

      this.historyOffset += Constants.Portal.PerPage * 10;
    } catch (e) {
      console.error(e);
      this.$toast.error('履歴の取得に失敗しました');
    } finally {
      this.isHistoryLoading = false;
    }

    // 再描画
    this.$forceUpdate();
  }

  historyDocumentTitleClickHandler(): void {
    this.$telemetry.sendClickTelemetry({ button: 'history__document-title' }, this.$route);
  }
  historyDocumentPageNoClickHandler(): void {
    this.$telemetry.sendClickTelemetry({ button: 'history__document-pageno' }, this.$route);
  }
  historySearchClickHandler(): void {
    this.$telemetry.sendClickTelemetry({ button: 'history__search' }, this.$route);
  }
  historyReadmoreClickHandler(): void {
    this.historyCurrent += 1;
    this.$telemetry.sendClickTelemetry({ button: 'history__load-more' }, this.$route);
  }

  assertHasDocument(history: History): history is DocBrowseEventExcerptWithRange {
    return 'document' in history;
  }
}
</script>
